#profile {
  .modalVoucher {
    .form-check-input[type="checkbox"].filled-in:checked + label:after,
    label.btn input[type="checkbox"].filled-in:checked + label:after {
      background-color: #33b5e5;
      border: 2px solid #33b5e5;
    }
  }

  td {
    vertical-align: middle;
    a {
      color: blue;
    }
  }
  .badge {
    padding: 0.4rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
  // Custom cards
  .card {
    color: #1e1e1e;
    background: white;
    border-radius: 0;
    box-shadow: none;
    .text-muted {
      color: #848e96;
    }
  }
  // Greeting
  .greeting {
    background: #2a3c4a;
    color: white;
    svg {
      max-height: 70px;
      margin-bottom: 1rem;
    }
  }
  .greeting-actions {
    background: #e6e6e6;
  }

  .alert {
    &.alert-danger {
      background: #f44336 !important;
      color: white;
    }
  }
  .badge-row {
    .col {
      padding: 0;
    }
    .badge {
      box-shadow: none;
      padding: 0.5rem;
    }
    .badge-elegant-color {
      background: #3e3e3e !important;
    }
    .badge-gold {
      margin-top: 1em;
      margin-bottom: 1em;
      background: linear-gradient(-45deg, gold 10%, #ffe866 70%, gold 50%);
      padding-right: 1em;
      font-size: 0.8em;
      padding-left: 1em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      box-shadow: 0px 0px 10px #ab930a;
      color: #2e2e2e !important;
    }
  }

  // Action buttons below post textarea
  .actions {
    .btn {
      padding: 0.7rem 1.5rem;
      i {
        color: gold;
      }
    }
  }

  // Tooltips
  .tooltip {
    span {
      color: white !important;
    }
  }

  // Headings
  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  // Custom buttons
  .btn-outline-red {
    color: white !important;
  }

  // Language switch
  .post-settings {
    span {
      margin-right: 10px;
      cursor: pointer;
      color: #5e5e5e;
      transition: color 0.1s ease;
      &:hover {
        color: #7e7e7e;
      }
    }
  }

  // Custom textarea
  textarea {
    resize: none;
    min-height: 59px;
    height: 59px;
    padding-top: 10px;
    overflow: hidden;
    padding-bottom: 25px; /* increased! */
  }
  .md-form {
    input,
    textarea {
      color: white !important;
    }
  }

  // Custom dropdown
  .dropdown-menu {
    background: #3e3e3e;
    padding: 0;
    button {
      color: white;
      i {
        width: 30px;
        color: lightgrey;
      }
    }
  }
  .dropdown-item {
    &.remove {
      background-color: #63403d;
      display: flex;
      justify-content: space-between;
      i {
        align-self: center;
        width: auto;
      }
    }
    &:focus,
    &:hover,
    &.active {
      box-shadow: none;
      color: white !important;
      text-decoration: none;
      background-color: #5e5e5e !important;
    }
  }

  .features {
    .lead {
      border-bottom: 2px #f44336 solid;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      display: inline-block;
      img {
        height: 25px;
        width: auto;
      }
    }
  }

  // Admin panel
  .admin-panel {
    background: #1e1e1e;
    color: white;
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019 Christian Aichner
 */
