#join {
  .md-progress .progress-bar {
    background-color: #ff8910 !important;
  }
  svg {
    max-width: 100%;
    max-height: 100px;
    margin: 0 0 1rem 0;
  }
  input {
    margin-bottom: 1rem;
    &.error{
      border-color: #ff8080;
      box-shadow: 0 0 0 0.2rem rgba(255,0,0,.25);
    }
  }
  .form-check-input[type="checkbox"].filled-in:checked + label:after,
  label.btn input[type="checkbox"].filled-in:checked + label:after {
    background-color: #ff8910;
    border: 2px solid #ff8910;
  }
  .splitter {
    overflow: hidden;
  }
  .or {
    position: relative !important;
    padding-bottom: 16px !important;
    padding-top: 16px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: var(--spacing-rule-text-vertical, 16px) !important;
    padding-top: var(--spacing-rule-text-vertical, 16px) !important;
    padding-left: var(--spacing-rule-text-horizontal, 16px) !important;
    padding-right: var(--spacing-rule-text-horizontal, 16px) !important;
  }
  .or-text {
    overflow-wrap: break-word !important;
    margin: 0px !important;
    &::before {
      border-bottom-width: var(--border-rule-border-width, 1px) !important;
      border-bottom-color: #e4e4e4 !important;
      content: "" !important;
      position: absolute !important;
      border-bottom-width: 1px !important;
      border-bottom-style: solid !important;
      border-bottom-color: rgb(228, 228, 228) !important;
      top: 50% !important;
      right: 100% !important;
      width: 5000px !important;
    }
    &::after {
      border-bottom-width: var(--border-rule-border-width, 1px) !important;
      border-bottom-color: #e4e4e4 !important;
      content: "" !important;
      position: absolute !important;
      border-bottom-width: 1px !important;
      border-bottom-style: solid !important;
      border-bottom-color: rgb(228, 228, 228) !important;
      top: 50% !important;
      left: 100% !important;
      width: 5000px !important;
    }
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019 Werbeagentur Christian Aichner
 */
