#hero {
  .view {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
  }

  h6 {
    line-height: 1.7;
  }

  .form-check {
    margin-bottom: 0 !important;
  }

  .rgba-black-light,
  .rgba-black-light:after {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

#info, #calltoaction, #support{
  background: #ff8910;
}

#service{
  svg{
    max-width: 100%;
    max-height: 200px;
  }
  .lead{
    border-bottom: 4px #ff8910 solid;
    display: inline-block;
    font-size: 1.6rem;
    span{
      color: #ff8910;
      margin-right: .3rem;
      font-size: 2rem;
    }
  }
}

#faq{
  .lead{
    border-bottom: 4px #ff8910 solid;
    display: inline-block;
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019 Werbeagentur Christian Aichner
 */
